
import usersApi from '@/api/common/users';
import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import { mapState } from "vuex";
export default {
    extends: baseComponent,
    props: {
        detailUserItem: {
            type: Object,
            default: null,
        },
        cardHeight: {
            type: String,
            default: 'height: calc(100dvh - 5rem);'
        },
        minInfo: {
            type: Boolean,
            default: false
        },
        ownedTarget: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            elements: {
                userSourceFilterList: [],
                querySearch: "",
                data: [],
                pagingItem: {
                    pageIndex: 1,
                    pageSize: 20,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: "created_at",
                    directionSort: "desc"
                }
            },
        }
    },
    watch: {
        detailUserItem(newVal) {
            if (this.detailUserItem !== null) this.getUsersReferalList(1);
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            staffsInfo: state => state.staffs,
            lowBalanceUser: state => state.lowBalanceUser,
        }),
        defaultPagingItem() {
            return {
                pageIndex: 1,
                pageSize: 20,
                numberOfPage: 1,
                outRowsNumber: 0,
                orderBy: "created_at",
                directionSort: "desc"
            };
        },
        requestParam() {
            return {
                querySearch: this.elements.querySearch,
                pageSize: this.elements.pagingItem.pageSize,
            };
        },
    },
    mounted() { this.getUsersReferalList(1); },
    methods: {
        getUsersReferalList(pageNumber) {
            this.showLoading();
            usersApi.getUsersReferalList(this.detailUserItem.id, pageNumber, this.requestParam).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.elements.data = response.data.data.data;
                    this.elements.pagingItem = response.data.data.pagingItem;
                    this.hideLoading();
                }
                else {
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        commonAct.showError(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.elements.data = [];
                this.elements.pagingItem = this.defaultPagingItem;
                this.hideLoading();
                commonAct.showError(error);

            });
        },
        getAllResult(pageNumber) {
            this.getUsersReferalList(pageNumber);
        },
        getSearchQueryResult(pageNumber) {
            this.getUsersReferalList(pageNumber);
        },
        pageClickHandle(pageNumber) {
            this.getSearchQueryResult(pageNumber);
        },
        refreshClick() {
            this.elements.querySearch = "";
            this.getAllResult(1);
        },
        onSearchInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFnc) this.debounceFnc();
            } else {
                this.getSearchQueryResult(1);
            }
        },
    }
}